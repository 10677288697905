import React, { useContext } from 'react';
import { Header } from 'semantic-ui-react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import { UserContext } from 'config/contexts';

const User = props => {
  const { user } = useContext(UserContext);
  return (
    <PageContainer>
      <Header>User Dashboard</Header>
      <Header>Welcome {user.firstName} {user.lastName}</Header>
    </PageContainer>
  )
}

export default withResponsiveContainerNav(User);