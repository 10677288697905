import React, { useState } from 'react';
import { Message, Header } from 'semantic-ui-react';

const FlashMessage = ({ message, ttl, type, removeFromStorage }) => {

  const [show, setShow] = useState(true);

  const messageProps = {};
  switch (type) {
    case 'warning':
      messageProps.warning = true;
      break;
    case 'error':
      messageProps.error = true;
      break;
    case 'success':
      messageProps.success = true;
      break;
    default:
      break;
  }

  const timer = setTimeout(() => {
    setShow(false);
    if (removeFromStorage !== null) {
      window.localStorage.removeItem(removeFromStorage);
    }
    clearTimeout(timer);
  }, ttl);

  if (show) {
    return (
      <Message {...messageProps}>
        <Header.Subheader>{message}</Header.Subheader>
      </Message>
    )
  }

  return null;
};

export default FlashMessage;