import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';

import { UserContextProvider } from 'config/contexts';

import { AdminRoutes } from 'feature/admin/routes';
import { UserRoutes } from 'feature/user/routes';
import { LabRoutes } from 'feature/lab/routes';
import Home from 'feature/shared/components/Home';

import './App.css';

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          {AdminRoutes()}
          {UserRoutes()}
          {LabRoutes()}
        </Switch>
      </Router>
    </UserContextProvider>
  );
}

export default App;
