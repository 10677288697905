import React, { useState, useContext, useEffect } from 'react';
import { Header, Loader, Container, Card, Segment, Label } from 'semantic-ui-react';
import moment from 'moment';
import { UserContext } from 'config/contexts';
import { withRouter } from 'react-router-dom';

import { get } from 'config/request';
import { apiRoutes } from 'config/api-routes';
import { statusColorMap } from 'config/constants';

const PlateStatusList = props => {
  const { statuses, history } = props;
  const [plates, setPlates] = useState({
    init: false,
    data: [],
  });
  const { user, updateUser } = useContext(UserContext);

  const getPlateStatuses = () => {
    get(apiRoutes.getPlateStatuses({status: statuses.join(',')}), {
      'Authorization': `Bearer ${user.token}`
    })
      .then(data => {
        if (data.success) {
          setPlates({
            init: true,
            data: data.data
          });
        } else {
          setPlates({
            init: true, data: []
          });
        }
        updateUser({
          ...user,
          token: data.token
        });
      })
  }

  useEffect(() => {
    if (plates.data.length === 0 && !plates.init) {
      getPlateStatuses();
    }
  });

  if (!plates.init) {
    return (
      <Container style={{ position: 'relative', minHeight: '50px' }}>
        <Loader active size='large' />
      </Container>
    )
  }

  return (
    <Container>
      <Header>Found {plates.data.length} Plates</Header>
      {plates.init && plates.data.map(plate => {
        const { color, label } = statusColorMap[plate.status];
        return (
          <Segment inverted key={`plate_${plate.unique_id}`}>
            <Card
              fluid
              color={color}
              link
              onClick={() => history.push(`/lab/plates/${plate.unique_id}`)}
            >
              <Card.Content>
                <Card.Header>{`${plate.machine.name} - ${plate.unique_id}`}</Card.Header>
                <Card.Meta>Created {moment(plate.create_date, "YYYYMMDD").fromNow()}</Card.Meta>
                <Card.Description>Some Content will go here describing the plate, recipe, samples.</Card.Description>
              </Card.Content>
              <Card.Content extra style={{alignItems: 'center', display: 'flex'}}>
                <div style={{flex: 1}}>Samples loaded: {plate.samples.length}</div>
                <Label style={{float: 'right'}} color={color}>{label}</Label>
              </Card.Content>
            </Card>
          </Segment>
        )
      })}
    </Container>
  )
}

export default withRouter(PlateStatusList);