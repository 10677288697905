import React, { useState, useContext, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import EditPlate from 'feature/lab/form/EditPlate';
import ViewPlate from 'feature/lab/components/ViewPlate';
import { apiRoutes } from 'config/api-routes';
import { UserContext } from 'config/contexts';
import { get } from 'config/request';
import { editablePlates } from 'config/constants';

const Plates = props => {
  const { match } = props;
  const { user } = useContext(UserContext);
  const [plate, setPlate] = useState({
    init: false,
    data: null,
  });

  const getPlateData = plateId => {
    get(apiRoutes.getPlate(plateId), {
      'Authorization': `Bearer ${user.token}`
    })
    .then(data => {
      if (data.success) {
        setPlate({
          init: true,
          data: data.data,
        });
      } else {
        setPlate({
          ...plate,
          init: true,
        });
      }
    });
  }

  useEffect(() => {
    if (!plate.init) {
      getPlateData(match.params.plate);
    }
  })

  const loadContent = () => {
    if (plate.init) {
      return editablePlates.includes(plate.status)
        ? <EditPlate plate={plate} />  
        : <ViewPlate plate={plate} />
    }
    return <Loader size='large' active>Loading</Loader>
  }

  return (
    <PageContainer>
      {loadContent(match.params.plate)}
    </PageContainer>
  );
};

export default withResponsiveContainerNav(Plates);