import React from 'react';
import {
  Form,
} from 'semantic-ui-react';
import { fieldTypes } from 'config/constants';


export const RenderField = props => {
  const { field, onChange, values } = props;
  switch (field.type) {
    case fieldTypes.PASSWORD:
      return (
        <Form.Input
          type={fieldTypes.PASSWORD}
          name={field.name}
          onChange={onChange}
          value={values[field.name]}
          {...field.props}
        />
      )
    case fieldTypes.INPUT:
    default:
      return (
        <Form.Input
          name={field.name}
          onChange={onChange}
          value={values[field.name]}
          {...field.props}
        />
      )
  }
}

export const FormFieldGroup = props => {
  const { group } = props;
  return (
    <Form.Group>
      {
        group.map(field => (
          <RenderField
            key={`field_input_${field.name}`}
            field={field}
          />
        ))
      }
    </Form.Group>
  )
};