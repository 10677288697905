import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from 'config/contexts';
import { Storage, LoggedOutMessages, Messages, MessageTypes, getRoleType, LoginTypes, LoginPaths, UserDataPaths, LandingPaths } from 'config/constants';
import { withResponsiveContainerNav } from 'feature/shared/containers/Containers';
import FlashMessage from 'feature/shared/components/FlashMessage';

import FormikLoginForm from 'feature/shared/form/components/Form';
import LoginFormTemplate from 'feature/shared/form/templates/LoginFormTemplate';
import { FormConfig } from 'config/formConfigs';

const LoginForm = props => {
  const [formMessages, setFormMessages] = useState([]);
  const { user, updateUser } = useContext(UserContext);
  const loggedOutMessage = JSON.parse(window.localStorage.getItem(Storage.LOGGED_OUT_REASON)) || null;
  const { type = LoginTypes.USER } = props;

  const submitHandler = (values, {setSubmitting }) => {
    let apiLoginUrl = `${process.env.REACT_APP_API_URL}/api/login_check`;
    if (LoginPaths[type]) {
      apiLoginUrl = `${process.env.REACT_APP_API_URL}${LoginPaths[type]}`;
    }
    
    fetch(apiLoginUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
    .then(res => res.json())
    .then(data => {
      if (data.code === 401) {
        console.log('showing login error?');
        setSubmitting(false);
        const loginErr = displayLoginError();
      
        setFormMessages([
          ...formMessages,
          loginErr,
        ]);
      } else {
        return  fetch(`${process.env.REACT_APP_API_URL}${UserDataPaths[type]}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${data.token}`
          }
        })
        .then(res => res.json())
        .then(userData => {
          
          const reducedUser = {
            ...user,
            authenticated: data.token !== null,
            firstName: userData.user.first_name,
            lastName: userData.user.last_name,
            token: data.token,
            type: getRoleType(userData.user.roles)
          };
  
          window.localStorage.setItem(Storage.USER, JSON.stringify(reducedUser));
          setSubmitting(false);
          updateUser(reducedUser);
        });
      }
    });
  }

  if ( user.authenticated ) {
    return <Redirect to={{pathname: LandingPaths[user.type], state: {from: props.location }}} />
  }

  if (loggedOutMessage !== null && formMessages.filter(m => m.name === 'logout_msg') < 1) {
    setTimeout(() => {
      setFormMessages(formMessages.filter(m => m.name !== 'logout_msg'));
    }, 3100);
    const logOutMsg = {
      name: 'logout_msg',
      render: (
        <FlashMessage
          removeFromStorage={Storage.LOGGED_OUT_REASON}
          type={loggedOutMessage.type}
          ttl={3000}
          message={LoggedOutMessages[loggedOutMessage.message]}
        />
      )
    };
    setFormMessages([
      ...formMessages,
      logOutMsg,
    ]);
  }

  const displayLoginError = () => {
    setTimeout(() => {
      setFormMessages(formMessages.filter(m => m.name !== 'login_err'));
    }, 3000);
    return { name: 'login_err', render: <FlashMessage type={MessageTypes.ERROR} message={Messages.LOGIN_ERROR} ttl={3000} /> };
  }

  const errorHandler = errors => {

  }

  return (
    <FormikLoginForm
      template={LoginFormTemplate}
      fields={FormConfig.LOGIN_FORM.fields}
      initialValues={FormConfig.LOGIN_FORM.initialValues}
      submitHandler={submitHandler}
      errorHandler={errorHandler}
      messages={formMessages}
    />
  )
}

export default withResponsiveContainerNav(LoginForm);