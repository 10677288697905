import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import PlateStatusList from 'feature/lab/components/PlateStatusList';
import SampleCheckin from 'feature/lab/components/SampleCheckin';

const Lab = props => {
  return (
    <PageContainer>
      <Header>Lab Dashboard</Header>
      <SampleCheckin />
      <Segment>
        <Header>Plate Status</Header>
        <PlateStatusList statuses={['completed','started','not_started']} />
      </Segment>
    </PageContainer>
  )
}

export default withResponsiveContainerNav(Lab);