import React from 'react';
import { Route } from 'react-router-dom';

import AuthenticatedRoute from 'feature/shared/components/AuthenticatedRoute';
import LoginForm from 'feature/shared/components/LoginForm';
import Register from 'feature/shared/components/Register';
import Results from 'feature/user/components/Results';
import Dashboard from 'feature/user/components/User';
import Kits from 'feature/user/components/Kits';
import Profile from 'feature/user/components/Profile';
import ActivateKit from 'feature/user/components/ActivateKit';

import { Permissions } from 'config/constants';

export const UserRoutes = () => (
  [
    <AuthenticatedRoute permissions={Permissions.USER} key='user_home' path="/user" exact component={Dashboard} />,
    <Route key='user_activate_kit' path='/user/kit/activate' exact component={ActivateKit} />,
    <Route key='user_login' path="/user/login" exact component={LoginForm} />,
    <Route key='user_register' path="/user/register" exact component={Register} />,
    <AuthenticatedRoute permissions={Permissions.USER} key='user_results' path="/user/results" component={Results} />,
    <AuthenticatedRoute permissions={Permissions.USER} key='user_kits' path="/user/kits" component={Kits} />,
    <AuthenticatedRoute permissions={Permissions.USER} key='user_profile' path="/user/profile" component={Profile} />,
  ]
);
