import React, { useState, useEffect, useContext } from 'react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import { Header, Table, Loader } from 'semantic-ui-react';
import { apiRoutes } from 'config/api-routes';
import { get } from 'config/request';
import { UserContext } from 'config/contexts';
import moment from 'moment';

const OrderList = props => {
  const { orders } = props;
  if (orders.length === 0) {
    return (
      <Loader active size="large">Loading</Loader>
    )
  }

  return (
    <Table size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Order ID</Table.HeaderCell>
          <Table.HeaderCell>Order Status</Table.HeaderCell>
          <Table.HeaderCell>Item</Table.HeaderCell>
          <Table.HeaderCell>Order Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {orders.map(order => {
        console.log(order.items);
        return (
          <Table.Row>
            <Table.Cell>
              {order.orderId}
            </Table.Cell>
            <Table.Cell>
              {order.orderStatus}
            </Table.Cell>
            <Table.Cell>
              {order.items.length ? order.items[0].name : 'N/A'}
            </Table.Cell>
            <Table.Cell>
              {moment(order.orderDate).format('MMMM Do YYYY, h:mm a')}
            </Table.Cell>
          </Table.Row>
        )
      })}    
    </Table>
  )
}

const Orders = props => {
  const { user } = useContext(UserContext);
  const [orders, setOrders] = useState({
    init: false,
    data: {
      orders: [],
    },
  });

  useEffect(() => {
    if (orders.data.orders.length === 0 && !orders.init) {
      console.log('getting orders');
      get(apiRoutes.getOrders(), {
        'Authorization': `Bearer ${user.token}`,
      })
        .then(data => {
          console.log(data);
          if (data.success) {
            setOrders({
              init:true,
              data: data.data,
            });
          }
        })
    }
  })

  return (
    <PageContainer>
      <Header>Order List</Header>
      <OrderList orders={orders.data.orders} />
    </PageContainer>
  )
}

export default withResponsiveContainerNav(Orders);