import React from 'react';
import { Formik } from 'formik';
import GenericTemplate from 'feature/shared/form/templates/GenericTemplate';

const Form = props => {
  const {fields, initialValues, errorHandler, submitHandler, template:RenderTemplate = GenericTemplate, messages } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <RenderTemplate
            fields={fields}
            values={values}
            onChange={handleChange}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            errorHandler={errorHandler}
            errors={errors}
            messages={messages}
          />
        </>
      )}
    </Formik>
  )
}

export default Form;