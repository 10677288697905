import React, { useContext } from 'react';
import { Menu, Container, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { UserContext } from 'config/contexts';
import { Storage, Messages, MessageTypes } from 'config/constants';

const filterNavItems = (navItems, authenticated) => {
  return navItems.filter(navItem => {
    if (Array.isArray(navItem)) {
      return filterNavItems(navItem, authenticated);
    }

    if ((authenticated && navItem.requiresAuth) || (!navItem.requiresAuth && !navItem.hideWhenAuth)) {
      return navItem;
    }

    if (!authenticated && !navItem.requiresAuth) {
      return navItem;
    }
    return false;
  });
}

const MobileMenu = ({ navItems, authenticated }) => {
  const filteredNav = filterNavItems(navItems, authenticated);
  return (
    <>
      {filteredNav.map(navItem => {
        if (Array.isArray(navItem)) {
          return (
            <Menu.Menu key={`mobile_menu_${(new Date()).getTime()}`}>
              <MobileMenu navItems={navItem} />
            </Menu.Menu>
          )
        }
        let onClick;
        let renderItem;
        if (navItem.props !== undefined && navItem.props.onClick) {
          switch (navItem.props.onClick) {
            case 'logoutUser':
              onClick = () => {
                console.log('logging out');
              }
              renderItem = navItem.label;
              break;
            default:
              onClick = null;
              renderItem = (
                <Link to={navItem.url}>
                  {navItem.label}
                </Link>
              );
              break;
          }
        }
        return (
          <Menu.Item onClick={() => onClick()} key={`mobile_menu_item_${navItem.label}_${navItem.url}`}>
            {renderItem}
          </Menu.Item>
        )
      })}
    </>
  );
}

const StandardMenu = ({ navItems, fixed, authenticated }) => {
  const filteredNav = filterNavItems(navItems, authenticated);
  const { user, updateUser } = useContext(UserContext);

  return (
    <Menu
      fixed={fixed ? 'top' : null}
      inverted={!fixed}
      pointing={!fixed}
      secondary={!fixed}
      size='large'
    >
      <Container>
        {filteredNav.map(navItem => {
          const isActive = window.location.pathname === navItem.url;
          const { props = {} } = navItem;
          const { onClick: hasOnClick, ...rest } = props;
          if (props !== undefined && hasOnClick) {
            switch (hasOnClick) {
              case 'logoutUser':
                const onClick = () => {
                  const newUser = {
                    ...user,
                    token: null,
                    authenticated: false,
                  };
                  window.localStorage.setItem(Storage.USER, JSON.stringify(newUser));
                  updateUser(newUser);
                  window.localStorage.setItem(Storage.LOGGED_OUT_REASON, JSON.stringify({
                    type: MessageTypes.SUCCESS,
                    message: Messages.SUCCESS_LOGOUT
                  }));
                }
                return (
                  <Menu.Item active={isActive} onClick={onClick} key={`menu_item_${navItem.label}_${navItem.url}`} {...rest}>
                    <Button href="">{navItem.label}</Button>
                  </Menu.Item>
                );
              default:
                return (
                  <Menu.Item active={isActive} key={`menu_item_${navItem.label}_${navItem.url}`}>
                    <Link to={navItem.url}>
                      {navItem.label}
                    </Link>
                  </Menu.Item>
                );
            }
          }
          return (
            <Menu.Item active={isActive} key={`menu_item_${navItem.label}_${navItem.url}`}>
              <Link to={navItem.url}>
                {navItem.label}
              </Link>
            </Menu.Item>
          );
        })}
      </Container>
    </Menu>
  );
}

const GatewayMenu = ({ navItems, mobile, fixed = false, authenticated = false }) => {
  const menuDisplay = mobile 
    ? <MobileMenu navItems={navItems} authenticated={authenticated} /> 
    : <StandardMenu navItems={navItems} fixed={fixed} authenticated={authenticated} />;
  return (
    <>
      {menuDisplay}
    </>
  );
};

export default GatewayMenu;