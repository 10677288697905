import React, { useState, useContext }  from 'react';
import { Formik } from 'formik';
import { Header, Segment, Form, Button, Grid, Message, Loader, Dropdown, Checkbox, TextArea } from 'semantic-ui-react';
import { UserContext } from 'config/contexts';
import { get } from 'config/request';
import { Messages, MessageTypes } from 'config/constants';

const CheckinSample = ({ data, sampleId }) => {
  return (
    <>
      <Header>Kit: {data.barcode}</Header>
      <Formik
        initialValues={{
          firstName: data.account.first_name || null,
          lastName: data.account.last_name || null,
          sampleId: sampleId,
          dateReceived: '',
          dateShipped: '',
          location:'',
          sampleCondition: '',
          sampleType: '',
          sampleVolume: '',
          shipToName: '',
          two_xinconclusive: false,
          prioritize: false,
          too_early: false,
          notes: ''
        }}
        onSubmit={(values, {setSubmitting}) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit
        }) => (
          <Form size='large'>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='Sample ID'
                    value={values.sampleId}
                    readOnly
                    name='sampleId'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input
                    label='Patient First Name'
                    value={values.firstName}
                    onChange={handleChange}
                    name='firstName'
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label='Patient Last Name'
                    value={values.lastName}
                    onChange={handleChange}
                    name='lastName'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field label='Sample Type' />
                  <Dropdown
                    onChange={(e, data) => {
                      const d = {
                        target: {
                          name: data.name,
                          value: data.value,
                        }
                      }
                      handleChange(d);
                    }}
                    name='sampleType'
                    placeholder='Choose a Sample Type'
                    selection
                    value={values.sampleType}
                    options={[
                      {key: 1, text: 'SP', value: 'SP'},
                      {key: 2, text: 'FT', value: 'FT'},
                      {key: 3, text: 'CA', value: 'CA'},
                      {key: 4, text: 'UK', value: 'UK'},
                      {key: 5, text: 'AU', value: 'AU'},
                      {key: 6, text: 'FTClinical', value: 'FTClinical'},
                      {key: 7, text: 'SPClinical', value: 'SPClinical'},
                      {key: 8, text: 'UKClinical', value: 'UKClinical'},
                      {key: 9, text: 'AUClinical', value: 'AUClinical'},
                      {key: 10, text: 'ODClinical', value: 'ODClinical'},
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field label='Sample Condition' />
                  <Dropdown
                    onChange={(e, data) => {
                      const d = {
                        target: {
                          name: data.name,
                          value: data.value,
                        }
                      }
                      handleChange(d);
                    }}
                    name='sampleCondition'
                    placeholder='Choose a Sample Condition'
                    selection
                    value={values.sampleCondition}
                    options={[
                      {key: 1, text: 'Good', value: 'Good'},
                      {key: 2, text: 'Clotted', value: 'Clotted'},
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field label='Sample Volume' />
                  <Dropdown
                    onChange={(e, data) => {
                      handleChange({
                        target: {
                          name: data.name,
                          value: data.value,
                        }
                      });
                    }}
                    name='sampleVolume'
                    placeholder='Select the Sample Volume'
                    selection
                    value={values.sampleVolume}
                    options={[
                      {key: 1, text: '200', value: 200},
                      {key: 2, text: '300', value: 300},
                      {key: 3, text: '400', value: 400},
                      {key: 4, text: '500', value: 500},
                      {key: 5, text: '600', value: 600},
                      {key: 6, text: '700', value: 700},
                      {key: 7, text: '800', value: 800},
                      {key: 8, text: '900', value: 900},
                      {key: 9, text: '1000+', value: 1000},
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <Checkbox
                      name='two_xinconclusive'
                      checked={values.two_xinconclusive === true}
                      value={1}
                      onChange={(e, data) => {
                        handleChange({
                          target: {
                            name: data.name,
                            value: data.checked,
                          }
                        })
                      }}
                      label='2x Inconclusive'
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name='prioritize'
                      checked={values.prioritize === true}
                      value={1}
                      onChange={(e, data) => {
                        handleChange({
                          target: {
                            name: data.name,
                            value: data.checked,
                          }
                        })
                      }}
                      label='Prioritize'
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name='too_early'
                      checked={values.too_early === true}
                      value={1}
                      onChange={(e, data) => {
                        handleChange({
                          target: {
                            name: data.name,
                            value: data.checked,
                          }
                        })
                      }}
                      label='Too Early?'
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field label='Ship To Name (if different)' />
                  <Form.Input
                    name='shipToName'
                    value={values.shipToName}
                    onChange={handleChange}
                    placeholder='First and Last Name'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field label='Notes' />
                  <TextArea
                    name='notes'
                    value={values.notes}
                    onChange={handleChange}
                    placeholder='Enter Notes'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button type='submit' disabled={isSubmitting} onClick={handleSubmit} color='teal'>Save</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

const KitView = props => {
  const { data, barcode, sampleId } = props;
  if (data !== false && data !== null && sampleId !== null && data.account !== null) {
    return <CheckinSample data={data} sampleId={sampleId} />
  }

  if (data.account === null) {
    return <Message error><Header>Kit is not yet activated by user</Header></Message>
  }

  if (data === false) {
    return <Message error><Header>No Kit Found for Barcode {barcode}</Header></Message>;
  }
  return null;
}

const CheckinState = props => {
  const {checkinData} = props;
  const { sampleId, showKitView, workingBarcode, kitData } = checkinData;
  if (
      showKitView &&
      workingBarcode && 
      kitData
  ) {
    return (
      <KitView barcode={workingBarcode} data={kitData} sampleId={sampleId} />
    )
  }
  return (<Loader size='large' />)
}

const SampleCheckin = props => {
  const [checkinState, setCheckinState] = useState({
    kitData: null,
    showKitView: null,
    workingBarcode: null,
    sampleId: null,
  });

  const [redirect, setRedirect] = useState(false);
  const { user, updateUser } = useContext(UserContext);

  if (redirect) {
    return redirect;
  }

  return (
    <Segment>
      <Header>Sample Check-In</Header>
      <Formik
        initialValues={{
          barcode: '',
        }}
        onSubmit={(values, {setSubmitting}) => {
          get(`/api/lab/kit/${values.barcode}`, {
            'Authorization': `Bearer ${user.token}`,
          })
          .then(response => {
            if (response.code !== null && response.code === 401) {
              setSubmitting(false);
              const newUser = {
                ...user,
                token: null,
                authenticated: false,
              };
              window.localStorage.setItem(Storage.USER, JSON.stringify(newUser));
              window.localStorage.setItem(Storage.LOGGED_OUT_REASON, JSON.stringify({
                type: MessageTypes.WARNING,
                message: Messages.INACTIVITY
              }));
              setRedirect(response.data);
            } else {
              user.token = response.token;
              setSubmitting(false);
              updateUser(user);
              setCheckinState({
                workingBarcode: values.barcode,
                kitData: response.data,
                sampleId: response.sampleId,
                showKitView: true
              });
            }
          });
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
        }) => (
          <Form size='large'>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input
                    label='Barcode'
                    name='barcode'
                    value={values.barcode}
                    onChange={handleChange}
                  />
                </Grid.Column>
                <Grid.Column style={{ alignItems: 'flex-end', display: 'flex'}}>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    color='teal'
                    onClick={handleSubmit}
                    size='large'
                  >
                    Submit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
      <CheckinState checkinData={checkinState} />
    </Segment>
  )
}

export default SampleCheckin;