import React, { useContext } from 'react';
import { Header, Grid } from 'semantic-ui-react';

import { Types } from 'config/constants';

import { withResponsiveContainerNav } from 'feature/shared/containers/Containers';
import { UserContext } from 'config/contexts';

const LabUserRegister = props => {
  return (
    <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 650 }}>
        <Header as='h2' color='teal' textAlign='center'>
          Lab User Creation
        </Header>
      </Grid.Column>
    </Grid>
  )
}

const UserRegistrationForm = props => {
  return (
    <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 650 }}>
        <Header as='h2' color='teal' textAlign='center'>
          User Registration Form
        </Header>
      </Grid.Column>
    </Grid>
  )
}

const Register = props => {
  const { user } = useContext(UserContext);
  const { type:userType } = user;
  let formToShow;
  switch ( userType ) {
    case Types.LAB_USER:
      formToShow = (<LabUserRegister />);
      break;
    case Types.USER:
    default:
      formToShow = (<UserRegistrationForm />);
      break;
  }
  return formToShow;
};

export default withResponsiveContainerNav(Register);