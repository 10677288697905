import React from 'react';
import { Card, Header, Container, Segment, Table, Label } from 'semantic-ui-react';
import moment from 'moment';

import { statusColorMap } from 'config/constants';

const SampleList = props => {
  if (props.samples.length === 0) {
    return (
      <Header size="small">No Samples Loaded</Header>
    )
  }
  return (
    <Card.Group stackable>
      {props.samples.map(s => {
        const attributes = [
          {
            name: 'Sample ID',
            value: s.sample_id,
          },
          {
            name: 'Barcode',
            value: s.kit.barcode,
          },
          {
            name: 'Condition',
            value: s.sample_condition || 'N/A',
          },
          {
            name: 'Sample Type',
            value: s.sample_type || 'N/A',
          }
        ];
        return (
          <Card fluid key={`sample_${s.sample_id}`}>
            <Card.Description>
              <Table definition size="small">
                <Table.Body>
                  {attributes.map(a => (
                    <Table.Row key={`sample_attr_${s.sample_id}_${a.name}`}>
                      <Table.Cell>{a.name}</Table.Cell>
                      <Table.Cell>{a.value}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card>
        );
      })}
    </Card.Group>
  )
}

const ViewPlate = props => {
  const { plate } = props;
  const { color, label } = statusColorMap[plate.data.status];

  const plateAttributes = [
    {
      name: 'Created On',
      value: moment(plate.data.create_date).format('MMMM Do YYYY, h:mm a'),
    },
    {
      name: 'Created By',
      value: plate.data.created_by !== null ? plate.data.created_by.username : 'N/A',
    },
    {
      name: 'Current Status',
      value: <Label color={color}>{label}</Label>
    },
    {
      name: 'Started By',
      value: plate.data.started_by !== null ? plate.data.started_by.username : 'N/A',
    },
    {
      name: 'Date Started',
      value: plate.data.date_start !== null ? moment(plate.data.date_start).format('MMMM Do YYYY, h:mm a') : 'N/A',
    },
    {
      name: 'Date Completed',
      value: plate.data.date_end !== null ? moment(plate.data.date_end).format('MMMM Do YYYY, h:mm a') : 'N/A',
    }
  ]

  return (
    <Container text>
      <Header>View Plate - {plate.data.unique_id}</Header>
      <Segment color="green">
        <Header size="small">Machine</Header>
        <Container text>
          {plate.data.machine.name}
        </Container>
      </Segment>
      <Segment color="green">
        <Header size="small">Plate Information</Header>
          <Table definition size="small">
            <Table.Body>
              {plateAttributes.map(attr => (
                <Table.Row>
                  <Table.Cell>{attr.name}</Table.Cell>
                  <Table.Cell>{attr.value}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
      </Segment>
      <Segment color="green">
        <Header size="small">Samples</Header>
        <SampleList samples={plate.data.samples} />
      </Segment>
    </Container>
  )
}

export default ViewPlate;