import React, { useState } from 'react';
import base64url from 'base64url';

import { Messages, Storage, MessageTypes } from 'config/constants';

export const initialUser = {
  authenticated: false,
  firstName: null,
  lastName: null,
  token: null,
  type: null
}

const defaultUser = () => {
  if (window.localStorage.getItem(Storage.USER)) {
    try {
      const localUser = JSON.parse(window.localStorage.getItem(Storage.USER));
      if (localUser.token === null) {
        return initialUser;
      }
      const tokenData = localUser.token.split('.');
      const d = JSON.parse(base64url.decode(tokenData[1]));
      const currentTime = Math.floor(Date.now()/1000);
    
      if (d.exp < currentTime) {
        window.localStorage.removeItem(Storage.USER);
        window.localStorage.setItem(Storage.LOGGED_OUT_REASON, JSON.stringify({
          type: MessageTypes.WARNING,
          message: Messages.INACTIVITY
        }));
        return initialUser;
      }

      return localUser;
    } catch (e) {
      return initialUser;
    }
  }
  return initialUser;
}

export const defaultContext = {
  user: defaultUser(),
  updateUser: () => {}
};

export const UserContext = React.createContext();

export const UserContextProvider = ({ children }) => {
  const [user, updateUser] = useState(defaultContext.user);

  const logoutUser = () => {
    const newUser = {
      ...user,
      token: null,
      authenticated: false,
    };
    updateUser(newUser);
  };

  const value = { user, updateUser, logoutUser };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};
