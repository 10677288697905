import { fieldTypes } from 'config/constants';

export const FormConfig = {
  LOGIN_FORM: {
    fields: [
      {
        type: fieldTypes.INPUT,
        name: 'username',
        props: {
          fluid: true,
          icon: 'user',
          iconPosition: 'left',
          placeholder: 'Email Address/Username'
        }
      },
      {
        type: fieldTypes.PASSWORD,
        name: 'password',
        props: {
          fluid: true,
          icon: 'lock',
          iconPosition: 'left',
          placeholder: 'Password'
        }
      }
    ],
    initialValues: {
      username: '',
      password: '',
    }
  }
}