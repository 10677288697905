import React, { useState, useContext, useEffect } from 'react';
// import { Formik } from 'formik';
import { Container, Header } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { UserContext } from 'config/contexts';
import { Card } from 'semantic-ui-react';
import { get } from 'config/request';
import { apiRoutes } from 'config/api-routes';

const SampleCard = props => {
  const { item, index } = props;
  return (
    <Draggable
      draggableId={`item_${item.sample_id}`}
      index={index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className='sampleCard'
        >
          <Card>
            <Card.Header>{item.sample_id}</Card.Header>
            <Card.Description>{item.sample_id}</Card.Description>
          </Card>
        </div>
      )}
    </Draggable>
  );
}

const SampleList = props => {
  const {
    provided, samples, type
  } = props;
  return (
    <div ref={provided.innerRef} className='dragDropZone'>
      <Header>{type}</Header>
      {samples.map((item, index) => (
        <SampleCard item={item} index={index} key={item.sample_id} />
      ))}
      {provided.placeholder}
    </div>
  )
}

const EditPlate = props => {
  const { plate } = props;
  const { user } = useContext(UserContext);

  const [samples, setSamples] = useState({
    init: false,
    availableSamples: [],
    loadedSamples: plate.data.samples || []
  })

  const getList = list => {
    return samples[list];
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  }

  const dragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === 'loadedSamples') {
        setSamples({
          ...samples,
          loadedSamples: items,
        })
      } else {
        setSamples({
          ...samples,
          availableSamples: items,
        })
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setSamples({
        ...samples,
        availableSamples: result.availableSamples,
        loadedSamples: result.loadedSamples,
      })
    }
  }

  const getAvailableSamples = () => {
    get(apiRoutes.getAvailableSamples(), {
      'Authorization': `Bearer ${user.token}`,
    })
    .then(data => {
      const loadedSamples = samples.loadedSamples.map(s => {
        return s.sample_id;
      });

      const availableSamples = data.data.filter(s => {
        return !loadedSamples.includes(s.sample_id);
      });

      setSamples({
        ...samples,
        init: true,
        loadedSamples: samples.loadedSamples,
        availableSamples
      })
    })
  }

  useEffect(() => {
    if (!samples.init) {
      getAvailableSamples();
    }
  })

  return (
    <Container
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
      }}
    >
      <Header>Edit Plate {plate.data.unique_id}</Header>
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="availableSamples">
          {(provided, snapshot) => (
            <SampleList
              provided={provided}
              snapshot={snapshot}
              samples={samples.availableSamples}
              type="availableSamples"
            />
          )}
        </Droppable>
        <Droppable droppableId="loadedSamples">
          {(provided, snapshot) => (
            <SampleList
              provided={provided}
              snapshot={snapshot}
              samples={samples.loadedSamples}
              type="loadedSamples"
            />
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}

export default EditPlate;
