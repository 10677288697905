import React from 'react';
import { Route } from 'react-router-dom';

import AuthenticatedRoute from 'feature/shared/components/AuthenticatedRoute';
import Register from 'feature/shared/components/Register';
import Lab from 'feature/lab/components/Lab';
import LabUsers from 'feature/lab/components/LabUsers';
import Kits from 'feature/lab/components/Kits';
import Reports from 'feature/lab/components/Reports';
import LoginForm from 'feature/shared/components/LoginForm';
import Plates from 'feature/lab/components/Plates';
import { LoginTypes, Permissions } from 'config/constants';

export const LabRoutes = () => (
  [
    <Route key='lab_login' path='/lab/login' exact component={() => <LoginForm type={LoginTypes.LAB} />} />,
    <AuthenticatedRoute permissions={Permissions.LAB} key='lab_home' path="/lab" exact component={Lab} />,
    <AuthenticatedRoute permissions={Permissions.LAB} key='lab_list_users' path="/lab/users" exact component={LabUsers} />,
    <Route key='lab_create_user' path="/lab/users/create" exact component={Register} />,
    <AuthenticatedRoute permissions={Permissions.LAB} key='lab_list_kits' path="/lab/kits" component={Kits} />,
    <AuthenticatedRoute permissions={Permissions.LAB} key='lab_list_reports' path="/lab/reports" component={Reports} />,
    <AuthenticatedRoute permissions={Permissions.LAB} key='lab_plates' path="/lab/plates/:plate?" component={Plates} />,
  ]
);