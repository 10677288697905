const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_USER = 'ROLE_USER';
const ROLE_LAB = 'ROLE_LAB';
const ROLE_CLINIC = 'ROLE_CLINIC';

const PLATE_STATUS_NOT_STARTED = 'not_started';
const PLATE_STATUS_PENDING = 'pending';
// const PLATE_STATUS_STARTED = 'started';
// const PLATE_STATUS_ERROR = 'error';
// const PLATE_STATUS_STATE1 = 'unknown_state';
// const PLATE_STATUS_STATE2 = 'unknown_state2';
// const PLATE_STATUS_STATE3 = 'unknown_state3';
const PLATE_STATUS_COMPLETED = 'completed';

export const Types = {
  USER: ROLE_USER,
  LAB_USER: ROLE_LAB,
}

export const MessageTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const Storage = {
  LOGGED_OUT_REASON: 'loggedOutReason',
  USER: 'user',
};

export const Messages = {
  INACTIVITY: 'inactive',
  LOGIN_ERROR: 'Invalid Username or Password',
  SUCCESS_LOGOUT: 'success_logout'
};

export const LoggedOutMessages = {
  [Messages.INACTIVITY]: 'You have been logged out due to inactivity',
  [Messages.SUCCESS_LOGOUT]: 'You have been successfully logged out',
};

export const Permissions = {
  CLINIC: [ROLE_CLINIC, ROLE_ADMIN],
  USER: [ROLE_USER, ROLE_ADMIN],
  LAB: [ROLE_LAB, ROLE_ADMIN],
  ADMIN: [ROLE_ADMIN],
};

export const LoginTypes = {
  CLINIC: ROLE_CLINIC,
  USER: ROLE_USER,
  ADMIN: ROLE_ADMIN,
  LAB: ROLE_LAB,
};

export const LogoutPaths = {
  [ROLE_CLINIC]: '/clinic/login',
  [ROLE_USER]: '/',
  [ROLE_ADMIN]: '/admin/login',
  [ROLE_LAB]: '/lab/login',
}

export const LandingPaths = {
  [ROLE_CLINIC]: '/clinic',
  [ROLE_USER]: '/user',
  [ROLE_ADMIN]: '/admin',
  [ROLE_LAB]: '/lab',
}

export const fieldTypes = {
  INPUT: 'text',
  PASSWORD: 'password'
};

export const LoginPaths = {
  [ROLE_CLINIC]: '/api/clinic_login_check',
  [ROLE_USER]: '/api/login_check',
  [ROLE_ADMIN]: '/api/admin_login_check',
  [ROLE_LAB]: '/api/lab_login_check',
};

export const UserDataPaths = {
  [ROLE_CLINIC]: '/api/clinic/user',
  [ROLE_USER]: '/api/user',
  [ROLE_ADMIN]: '/api/admin/user',
  [ROLE_LAB]: '/api/lab/user',
}

export const editablePlates = [
  PLATE_STATUS_NOT_STARTED,
  PLATE_STATUS_PENDING,
];

export const statusColorMap = {
  [PLATE_STATUS_COMPLETED]: {
    color: 'green',
    label: 'Completed',
  },
  [PLATE_STATUS_NOT_STARTED]: {
    color: 'yellow',
    label: 'Not Started',
  },
  [PLATE_STATUS_PENDING]: {
    color: 'grey',
    label: 'Pending',
  },
}

export const getRoleType = roles => {
  let userLevel = 0;
  roles.forEach(role => {
    switch (role) {
      case LoginTypes.ADMIN:
        userLevel += 8;
        break;
      case LoginTypes.LAB:
        userLevel += 4;
        break;
      case LoginTypes.CLINIC:
        userLevel += 2;
        break;
      case LoginTypes.USER:
      default:
        userLevel += 1;
        break;
    }
  });

  let roleType = ROLE_USER;

  if (userLevel >= 2) {
    roleType = ROLE_CLINIC;
  }

  if (userLevel >= 4) {
    roleType = ROLE_LAB;
  }

  if (userLevel >= 8) {
    roleType = ROLE_ADMIN;
  }

  return roleType;
}