import React, { useContext } from 'react';
import base64url from 'base64url';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from 'config/contexts';
import { Storage, Messages, MessageTypes, LogoutPaths } from 'config/constants';

const  AuthenticatedRoute = ({component: Component, permissions, ...rest }) => {
  const { user } = useContext(UserContext);
  const { token } = user;
  let decryptedToken = {
    exp: new Date(),
    roles: [],
  };
  let validToken = false;

  if (token) {
    const tokenData = token.split('.');
    decryptedToken = JSON.parse(base64url.decode(tokenData[1]));
    validToken = decryptedToken.exp > (Date.now()/1000);
  }

  const loggedOutReasonExists = (window.localStorage.getItem(Storage.LOGGED_OUT_REASON));

  if (!validToken && !loggedOutReasonExists) {
    user.authenticated = false;
    user.token = null;
    window.localStorage.setItem(Storage.USER, JSON.stringify(user))
    window.localStorage.setItem(Storage.LOGGED_OUT_REASON, JSON.stringify({
      type: MessageTypes.WARNING,
      message: Messages.INACTIVITY
    }));
  }

  const logoutPath = LogoutPaths[user.type];

  return (
    <Route
      {...rest}
      render={props => user.authenticated && validToken
        ? <Component {...props} />
        : <Redirect to={{pathname: logoutPath, state: {from: props.location }}} />
      }
    />
  )
};

export default AuthenticatedRoute;