import React, { useContext, useState, useEffect } from 'react';
import { Header, Table, Icon, Message } from 'semantic-ui-react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import { UserContext } from 'config/contexts';
import { get } from 'config/request';
import { apiRoutes } from 'config/api-routes';

const UserKits = props => {
  const { kits } = props;
  return (
    <Table inverted>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Barcode</Table.HeaderCell>
          <Table.HeaderCell>Kit ID</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Kit Shipped</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Lab Received</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Results Ready</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {kits.map(kit => {
          return (
            <Table.Row>
              <Table.Cell>{kit.barcode}</Table.Cell>
              <Table.Cell>{kit.kit_id}</Table.Cell>
              <Table.Cell textAlign='center'>
                <Icon 
                  name='check circle outline'
                  color={kit.date_shipped !== null ? 'green' : 'red'}
                />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Icon 
                  name='check circle outline'
                  color={kit.date_received !== null ? 'green' : 'red'}
                />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Icon 
                  name='check circle outline'
                  color={kit.results !== null ? 'green' : 'red'}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const Kits = props => {
  const { location } = props;
  const { user } = useContext(UserContext);
  const [userKits, setUserKits] = useState([]);
  const [init, setInit] = useState(false);

  const getUserData = () => {
    get(apiRoutes.getUserData(user.type), {
      'Authorization': `Bearer ${user.token}`
    })
      .then(data => {
        if (data.user.kits) {
          setUserKits(data.user.kits);
        } else {
          setUserKits([]);
        }
        setInit(true);
      })
  }

  useEffect(() => {
    if (userKits.length === 0 && !init) {
      getUserData();
    }
  });

  return (
    <PageContainer>
      <Header>Kits</Header>
      <Header>Welcome {user.firstName} {user.lastName}</Header>
      {location.state !== undefined && location.state.message && (<Message success>{location.state.message}</Message>)}
      <UserKits kits={userKits} />
    </PageContainer>
  )
}

export default withResponsiveContainerNav(Kits);