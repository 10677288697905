import React from 'react';

import LoginForm from 'feature/shared/components/LoginForm';

const Home = props => {
  return (
    <LoginForm />
  )
};

export default Home;