import { Permissions } from 'config/constants';

export const genericRoutes = [
  {
    url: '/',
    label: 'Logout',
    requiresAuth: true,
    props: {
      position: 'right',
      onClick: 'logoutUser'
    }
  }
]

export const UserNavItems = [
  {
    url: '/user',
    label: 'Dashboard',
    requiresAuth: true,
    roles: Permissions.USER
  },
  {
    url: '/user/results',
    label: 'Results',
    requiresAuth: true,
    roles: Permissions.USER
  },
  {
    url: '/user/kits',
    label: 'My Kits',
    requiresAuth: true,
    roles: Permissions.USER
  },
  {
    url: '/user/kit/activate',
    label: 'Activate Kit',
    requiresAuth: false,
  },
  {
    url: '/user/profile',
    label: 'My Profile',
    requiresAuth: true,
    roles: Permissions.USER
  },
  {
    url: '/user/login',
    label: 'Login',
    requiresAuth: false,
    hideWhenAuth: true,
  },
  {
    url: '/user/register',
    label: 'Register',
    requiresAuth: false,
    hideWhenAuth: true,
  },
  ...genericRoutes
];

export const LabNavItems = [
  {
    url: '/lab',
    label: 'Dashboard',
    requiresAuth: true,
    roles: Permissions.LAB
  },
  {
    url: '/lab/users',
    label: 'Lab Users',
    requiresAuth: true,
    roles: Permissions.LAB
  },
  {
    url: '/lab/kits',
    label: 'Kits',
    requiresAuth: true,
    roles: Permissions.LAB
  },
  {
    url: '/lab/reports',
    label: 'Reports',
    requiresAuth: true,
    roles: Permissions.LAB
  },
  {
    url: '/admin',
    label: 'Admin Dashboard',
    requiresAuth: true,
    roles: Permissions.LAB
  },
  {
    url: '/admin/orders',
    label: 'Orders',
    requiresAuth: true,
    roles: Permissions.LAB
  },
  ...genericRoutes
];

export const AdminNavItems = [
  {
    url: '/admin',
    label: 'Dashboard',
    requiresAuth: true,
    roles: Permissions.ADMIN
  },
  {
    url: '/admin/users',
    label: 'Manage Users',
    requiresAuth: true,
    roles: Permissions.ADMIN
  },
  {
    url: '/admin/kits',
    label: 'Manage Kits',
    requiresAuth: true,
    roles: Permissions.ADMIN
  },
  {
    url: '/admin/reports',
    label: 'Reports',
    requiresAuth: true,
    roles: Permissions.ADMIN
  },
  ...genericRoutes
];

export const ClinicNavItems = [
  {
    url: '/clinic',
    label: 'Dashboard',
    requiresAuth: true,
    roles: Permissions.CLINIC,
  },
];