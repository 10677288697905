import React from 'react';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { FormFieldGroup, RenderField } from 'feature/shared/form/helpers/functions';

const LoginFormTemplate = props => {
  const { fields, values, onChange, onSubmit, isSubmitting, errorHandler, errors, messages } = props;
  return (
    <Grid textAlign='center' style={{ margin: 0, flexGrow: 1 }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='teal' textAlign='center'>
          Log in to your account
        </Header>
        <Form size='large' onSubmit={onSubmit}>
          <Segment stacked>
            {fields.map(field => {
              if (Array.isArray(field)) {
                return <FormFieldGroup group={field} />
              }

              if (typeof field === 'object') {
                return <RenderField key={field.name} field={field} onChange={onChange} values={values} />
              }
              
              return null;
            })}
            <Button type='submit' disabled={isSubmitting} color='teal' onClick={onSubmit} fluid size='large'>
              Login
            </Button>
          </Segment>
        </Form>
        {errors.length > 0 && errorHandler(errors)}
        {messages.map(m => {
          return m.render;
        })}
      </Grid.Column>
    </Grid>
  );
};

export default LoginFormTemplate