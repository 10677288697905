import React from 'react';
import { Header } from 'semantic-ui-react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';

const LabReports = props => {
  return (
    <PageContainer>
      <Header>Lab - Reports</Header>
    </PageContainer>
  )
}

export default withResponsiveContainerNav(LabReports);