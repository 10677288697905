import React from 'react';
import { Redirect } from 'react-router-dom';
import { Storage, LandingPaths } from 'config/constants';

const makeRequest = (method, endpoint, headers, body) => {

  const url = `${process.env.REACT_APP_API_URL}${endpoint}`;

  let requestConfig = {
    method,
    headers: {
      ...headers,
    }
  };

  if (body) {
    requestConfig.body = body;
  }

  return fetch(url, requestConfig)
    .then(res => {
      if (res.status === 401) {
        const user = JSON.parse(window.localStorage.getItem(Storage.USER));
        return ({
          data: <Redirect to={{pathname: LandingPaths[user.type]}} />,
          code: 401,
        });
      } else {
        return res.json();
      }
    })
    .catch(e => ({ success: false, message: e.message }))
    .then(data => {
      return data;
    });
}

const contentType = {
  'Content-Type': 'application/json',
};

export const get = (endpoint, headers = {}) => {
  return makeRequest('GET', endpoint, {...contentType, ...headers});
}

export const del = (endpoint, headers = {}) => {
  return makeRequest('DELETE', endpoint, {...contentType, ...headers});
}

export const post = (endpoint, data, headers = {}) => {
  return makeRequest(
    'POST',
    endpoint,
    {...contentType, ...headers},
    JSON.stringify(data),
  );
};

export const put = (endpoint, data, headers = {}) => {
  return makeRequest(
    'PUT',
    endpoint,
    {...contentType, ...headers},
    JSON.stringify(data),
  );
};

export const patch = (endpoint, data, headers = {}) => {
  return makeRequest(
    'PATCH',
    endpoint,
    {...contentType, ...headers},
    JSON.stringify(data),
  );
};

export const upload = (endpoint, data, headers = {}) => {
  return makeRequest(
    'POST',
    endpoint,
    {...headers},
    data,
  );
};