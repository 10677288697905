import React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';

const Admin = props => {
  return (
    <PageContainer>
      <Header>Admin Dashboard</Header>
      <Link to="/admin/orders" />
    </PageContainer>
  )
}

export default withResponsiveContainerNav(Admin);