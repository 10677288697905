import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import { Header, Segment, Form, Button, Grid, Radio, Dropdown, Input, Flag, Checkbox } from 'semantic-ui-react';

import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import { post } from 'config/request';
import { apiRoutes } from 'config/api-routes';
import { UserContext } from 'config/contexts';

const ActivateKit = props => {
  const { user, updateUser } = useContext(UserContext);
  const [eddOrDLMP, setEddOrDLMP] = useState(null);
  const [sampleCollected, setSampleCollected] = useState(null);
  const [kitActivated, setKitActivated] = useState(false);
  const [kitErrors, setKitErrors] = useState([])
  const [relPlaceholder, setRelPlaceholder] = useState('+1');

  const placeMaps = {
    '+1': '(555) 555-1212',
    '+44': '7911 123456',
  };

  if (kitActivated) {
    return (
      <Redirect
        to={{
          pathname: '/user/kits',
          state: {
            message: 'Congratulations, your kit has been activated!'
          }
        }}
      />
    )
  }

  if (kitErrors) {
    console.log(kitErrors);
  }

  return (
    <PageContainer>
      <Header>Activate Kit</Header>
      <Formik
        initialValues={{
          dlmp: '',
          edd: '',
          activationPriorToSample: null,
          sampleCollected: '',
          barcode: '',
          barcodeConfirm: '',
          firstName: '',
          lastName: '',
          phonePrefix: '+1',
          phoneNumber: '',
          dateOfBirth: '',
          emailAddress: '',
          emailAddressConfirm: '',
          resultsEmailAddress: '',
          resultsEmailAddressConfirm: '',
          orderNumber: '',
          tosAccept: null,
          mailDeliveryWaiver: null,
        }}
        onSubmit={(values, {setSubmitting}) => {
          const headers = user !== null 
            ? {'Authorization': `Bearer ${user.token}`}
            : {};

          post(apiRoutes.activateKit(), values, headers)
          .then(data => {
            updateUser({
              ...user,
              token: data.token
            })

            if (data.success) {
              setKitErrors([]);
              setKitActivated(true)
            } else {
              setKitErrors(data.data)
            }
          })
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => (
          <Form size='large'>
            <Segment stacked>
              <Grid style={{ margin: 0, flexGrow: 1}} verticalAlign='middle'>
                <Grid.Row className='form-row-wrapper'>
                  <div className='full-width'>
                    <Form.Field>
                      <h3>First, Let's check that you're at least 8 weeks into pregnancy. <sup>*</sup></h3>
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='Use the first day of your last menstrual period'
                        name='radioGroup'
                        value='dlmp'
                        checked={eddOrDLMP === 'dlmp'}
                        onChange={() => setEddOrDLMP('dlmp')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Use the baby's estimated due date"
                        name='radioGroup'
                        value='edd'
                        checked={eddOrDLMP === 'edd'}
                        onChange={() => setEddOrDLMP('edd')}
                      />
                    </Form.Field>
                  </div>
                  <div>
                    {eddOrDLMP === 'edd' && (
                      <Form.Input
                        label={<h3>Baby's Estimated Due Date <sup>*</sup></h3>}
                        value={values.edd}
                        name='edd'
                        width={4}
                        placeholder='MM/DD/YYYY'
                        onChange={handleChange}
                      />
                    )}
                    {eddOrDLMP === 'dlmp' && (
                      <Form.Input
                        label={<h3>First Day of Last Menstrual Period <sup>*</sup></h3>}
                        value={values.dlmp}
                        name='dlmp'
                        width={4}
                        placeholder='MM/DD/YYYY'
                        onChange={handleChange}
                      />
                    )}
                    {eddOrDLMP === null && (
                      <></>
                    )}
                  </div>
                  <div className='full-width'>
                    <Form.Field>
                      <h3>Are you activating your kit prior to collecting your sample? <sup>*</sup></h3>
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='Yes'
                        name='activationPriorToSample'
                        value={0}
                        checked={sampleCollected === 0}
                        onChange={() => {
                          handleChange({
                            target: {
                              name: 'activationPriorToSample',
                              value: true
                            }
                          });
                          setSampleCollected(0);
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label='No'
                        name='activationPriorToSample'
                        value={1}
                        checked={sampleCollected === 1}
                        onChange={() => {
                          handleChange({
                            target: {
                              name: 'activationPriorToSample',
                              value: false,
                            }
                          });
                          setSampleCollected(1);
                        }}
                      />
                    </Form.Field>
                  </div>
                  <div className='full-width'>
                    {sampleCollected === 1 && (
                      <Form.Input
                        label={<h3>What day was your sample collected? <sup>*</sup></h3>}
                        value={values.sampleCollected}
                        name='sampleCollected'
                        placeholder='Enter the date the sample was collected (MM/DD/YYYY)'
                        width={4}
                        onChange={handleChange}
                      />
                    )}
                    {!sampleCollected && (
                      <></>
                    )}
                  </div>
                  <div>
                    <Form.Input
                      label={<h3>Barcode <sup>*</sup></h3>}
                      type='text'
                      name='barcode'
                      value={values.barcode}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <Form.Input
                      label={<h3>Confirm Barcode <sup>*</sup></h3>}
                      type='text'
                      name='barcodeConfirm'
                      value={values.barcodeConfirm}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <Form.Field>
                      <h3>Name <sup>*</sup></h3>
                    </Form.Field>
                    <Form.Input
                      name='firstName'
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder='First Name'
                    />
                  </div>
                  <div style={{alignSelf: 'flex-end'}}>
                    <Form.Input
                      name='lastName'
                      value={values.lastName}
                      onChange={handleChange}
                      placeholder='Last Name'
                    />
                  </div>
                  <div>
                    <Form.Field>
                      <h3>Phone <sup>*</sup></h3>
                    </Form.Field>
                    <Input
                      label={
                        <Dropdown name='phonePrefix' onChange={(e, data) => {
                          const d = {
                            target: {
                              name: 'phonePrefix',
                              value: data.value
                            }
                          }
                          setRelPlaceholder(data.value);
                          handleChange(d);
                        }}
                        value={values.phonePrefix}
                        options={[
                          {key: '+1', text: (<><Flag name='us' /> +1</>), value: '+1'},
                          {key: '+44', text: (<><Flag name='uk' />+44</>), value: '+44'}
                        ]} />
                      }
                      placeholder={placeMaps[relPlaceholder]}
                      className='phone-with-prefix'
                      type='text'
                      value={values.phoneNumber}
                      name='phoneNumber'
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <Form.Input
                      label={(<h3>Your Date of Birth <sup>*</sup></h3>)}
                      name='dateOfBirth'
                      value={values.dateOfBirth}
                      onChange={handleChange}
                      placeholder='MM/DD/YYYY'
                    />
                  </div>
                  <div>
                    <Form.Input
                      label={(<h3>Email Address <sup>*</sup></h3>)}
                      name='emailAddress'
                      value={values.emailAddress}
                      onChange={handleChange}
                      placeholder='Enter Email Address'
                    />
                  </div>
                  <div>
                    <Form.Input
                      label={(<h3>Confirm Email Address <sup>*</sup></h3>)}
                      name='emailAddressConfirm'
                      value={values.emailAddressConfirm}
                      onChange={handleChange}
                      placeholder='Confirm Email Address'
                    />
                  </div>
                  <div>
                    <Form.Field>
                      <h3>Results Email, if different. Please Note:</h3> This is the email address that your gender test result will be sent to.
                    </Form.Field>
                    <Form.Input
                      name='resultsEmailAddress'
                      value={values.resultsEmailAddress}
                      onChange={handleChange}
                      placeholder='Enter Results Email'
                    />
                  </div>
                  <div style={{ alignSelf: 'flex-end'}}>
                    <Form.Input
                      name='resultsEmailAddressConfirm'
                      value={values.resultsEmailAddressConfirm}
                      onChange={handleChange}
                      placeholder='Confirm Results Email'
                    />
                  </div>
                  <div >
                    <Form.Field>
                      <h3>Order Number</h3> if you'd like return tracking info for your sample (optional)
                    </Form.Field>
                    <Form.Field>
                      <a href="/">How do I find my Order Number?</a>
                    </Form.Field>
                    <Form.Input
                      name='orderNumber'
                      value={values.orderNumber}
                      onChange={handleChange}
                      placeholder='Enter Order Number'
                    />
                  </div>
                  <div className='full-width'>
                    <Form.Field>
                      <h3>Terms of Service</h3>
                    </Form.Field>
                    <Form.Field style={{ flexDirection: 'row', display: 'flex', verticalAlign: 'center', alignItems: 'center'}}>
                      <Checkbox
                        name='tosAccept'
                        checked={values.tosAccept === 1}
                        value={1}
                        onChange={() => {
                          const d = {
                            target: {
                              name: 'tosAccept',
                              value: (values.tosAccept === 1) ? 0 : 1
                            }
                          }
                          handleChange(d);
                        }}
                        type='checkbox'
                      />
                      <p style={{ marginLeft: 10 }}>By clicking submit below, you agree to the <a href="/">SneakPeek Terms of Service</a></p>
                    </Form.Field>
                    <Form.Field style={{ flexDirection: 'row', display: 'flex', verticalAlign: 'center', alignItems: 'center'}}>
                      <Checkbox
                        name='mailDeliveryWaiver'
                        value={1}
                        checked={values.mailDeliveryWaiver === 1}
                        onChange={() => {
                          const d = {
                            target: {
                              name: 'mailDeliveryWaiver',
                              value: (values.mailDeliveryWaiver === 1) ? 0 : 1
                            }
                          }
                          handleChange(d);
                        }}
                        type='checkbox'
                      />
                      <p style={{ marginLeft: 10 }}>By clicking submit below, you agree to the <a href="/">Mail Delivery Waiver</a></p>
                    </Form.Field>
                  </div>
                </Grid.Row>
                <Button type='submit' disabled={isSubmitting} color='teal' onClick={handleSubmit} fluid size='large'>
                  Activate Kit
                </Button>
              </Grid>
            </Segment>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
}

export default withResponsiveContainerNav(ActivateKit);