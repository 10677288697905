import React from 'react';
import AuthenticatedRoute from 'feature/shared/components/AuthenticatedRoute';
import { Permissions } from 'config/constants';

import Admin from 'feature/admin/components/Admin';
import Orders from 'feature/admin/components/Orders';

export const AdminRoutes = () => (
  [
    <AuthenticatedRoute permissions={Permissions.LAB} key='admin_home' path="/admin" exact component={Admin} />,
    <AuthenticatedRoute permissions={Permissions.LAB} key='admin_list_orders' path="/admin/orders" exact component={Orders} />,
  ]
);