import { UserDataPaths } from 'config/constants';
export const apiRoutes = {
  getSampleIdForKit: barcode => (`/api/lab/kit/sampleId/${barcode}`),
  getKits: (page, limit, query) => {
    if (query === null) {
      return '/api/lab/kits?' + new URLSearchParams({ page, perPage: limit});
    }
    return '/api/lab/kits?' + new URLSearchParams({ page, perPage: limit, query});
  },
  activateKit: () => (`/api/kit/activation`),
  getUserData: type => UserDataPaths[type],
  getPlateStatuses: (args) => ('/api/lab/plate?' + new URLSearchParams(args)),
  getPlate: plate => (`/api/lab/plate/${plate}`),
  getAvailableSamples: () => (`/api/lab/sample/available`),
  getOrders: () => (`/api/order/orders`),
};