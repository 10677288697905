import React, { useState, useContext } from 'react';
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility
} from 'semantic-ui-react';

import { UserContext } from 'config/contexts';
import GatewayMenu from 'feature/shared/components/Menu';
import { Types } from 'config/constants';
import { UserNavItems, LabNavItems } from 'config/routes';

const getWidth = () => {
  const isSSR = typeof window === 'undefined';
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const DesktopContainer = ({ children, navItems, authenticated = false }) => {
  const [fixed, setFixed] = useState(false);
  const toggleMenu = () => {
    setFixed(!fixed);
  }

  return (
    <Responsive
      getWidth={getWidth}
      minWidth={Responsive.onlyTablet.minWidth}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex:1,
      }}
    >
      <Visibility
        once={false}
        onBottomPassed={toggleMenu}
        onBottomPassedReverse={toggleMenu}
      >
        <Segment
          inverted
          textAlign='center'
          vertical
        >
          <GatewayMenu navItems={navItems} fixed={fixed} authenticated={authenticated} />
        </Segment>
      </Visibility>
      {children}
    </Responsive>  
  );
};

const MobileContainer = ({ children, navItems, authenticated = false }) => {
  const [sidebarOpened, setSideBarOpened] = useState(false);
  const toggleSideBar = () => setSideBarOpened(!sidebarOpened);

  return (
    <Responsive
      as={Sidebar.Pushable}
      getWidth={getWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}
    >
      <Sidebar
        as={Menu}
        animation='push'
        inverted
        onHide={() => toggleSideBar()}
        vertical
        visible={sidebarOpened}
      >
        <GatewayMenu navItems={navItems} mobile authenticated={authenticated} />
      </Sidebar>
      <Sidebar.Pusher dimmed={sidebarOpened}>
        <Segment
          inverted
          textAlign='center'
          vertical
        >
          <Container>
            <Menu inverted pointing secondary size='large'>
              <Menu.Item onClick={() => toggleSideBar()}>
                <Icon name='sidebar' />
              </Menu.Item>
            </Menu>
          </Container>
        </Segment>
        {children}
      </Sidebar.Pusher>
    </Responsive>
  );
};

const ResponsiveContainerWithNav = ({ children }) => {
  const { user } = useContext(UserContext);
  let navItems;
  const { type:userType, authenticated } = user;
  switch (userType) {
    case Types.LAB_USER:
      navItems = LabNavItems;
      break;
    case Types.USER:
    default:
      navItems = UserNavItems;
      break;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex:1,
      }}
    >
      <MobileContainer navItems={navItems} authenticated={authenticated}>{children}</MobileContainer>
      <DesktopContainer navItems={navItems} authenticated={authenticated}>{children}</DesktopContainer>
    </div>
  );
}

const ResponsiveContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex:1,
    }}
  >
    <Responsive
      getWidth={getWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}
    >
      {children}
    </Responsive>
    <Responsive
      getWidth={getWidth}
      minWidth={Responsive.onlyTablet.minWidth}
    >
      {children}
    </Responsive>
  </div>
)

const withResponsiveContainerNav = WrappedComponent => {
  return class ResponsiveComponent extends React.Component {
    render() {
      return (
        <ResponsiveContainerWithNav>
          <WrappedComponent {...this.props} />
        </ResponsiveContainerWithNav>
      )
    }
  }
}

const PageContainer = ({children}) => {
  return (
    <Segment className="flexed">
      <Container className="flexed">
        {children}
      </Container>
    </Segment>
  )
}

export {
  DesktopContainer,
  MobileContainer,
  ResponsiveContainer,
  ResponsiveContainerWithNav,
  withResponsiveContainerNav,
  PageContainer
};