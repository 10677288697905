import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Header,
  Table,
  Message,
  Loader,
  Dimmer,
  Menu,
  Dropdown,
  Input,
  Icon
} from 'semantic-ui-react';
import { withResponsiveContainerNav, PageContainer } from 'feature/shared/containers/Containers';
import _ from 'lodash';

import { get } from 'config/request';
import { UserContext } from 'config/contexts';
import { apiRoutes } from 'config/api-routes';

const ViewKit = ({ kit, cancelView }) => {
  return (
    <>
      <Header>Editing Kit - {kit.kit_id}</Header>
      <Button onClick={cancelView}>Back</Button>
    </>
  );
}

const KitList = props => {
  const {
    kits,
    pages,
    paginator,
    noneFound,
    loading,
  } = props;

  const [currentPage, setCurrentPage] = useState({
    pageNum: 1
  });
  const [perPage, setPerPage] = useState(10);
  const [queryValue, setQueryValue] = useState('');
  const [kitView, setKitView] = useState(null);

  const GetPagination = props => {
    const handlePageChange = e => {
      setCurrentPage(e);
      paginator(e);
    }

    const paginate = [];

    if (pages > 5) {
      if (pages > 10) {
        paginate.push(
          <Menu.Item
            key={`page_pusher_left_10`}
            as='a'
            onClick={() => handlePageChange({pageNum: Math.max(currentPage.pageNum-10, 1), perPage})}
          >
            <Icon name="angle double left" />
          </Menu.Item>
        )
      }

      paginate.push(
        <Menu.Item
          key={`page_pusher_left_1`}
          as='a'
          onClick={() => handlePageChange({pageNum: Math.max(currentPage.pageNum-1, 1), perPage})}
        >
          <Icon name="angle left" />
        </Menu.Item>
      )

      const startPage = Math.max(1, Math.min(currentPage.pageNum - 2, pages-4));
      const endPage = Math.min(startPage + 4, pages);

      for (var currPage = startPage; currPage <= endPage; currPage++) {
        const pageData = {pageNum: currPage, perPage};
        paginate.push(
          <Menu.Item
            key={`page_index_page_${currPage}`}
            as='a'
            active={currentPage.pageNum === currPage}
            onClick={() => handlePageChange(pageData)}
          >
            {currPage}
          </Menu.Item>
        );
      }

      paginate.push(
        <Menu.Item
          key={`page_pusher_right_1`}
          as='a'
          onClick={() => handlePageChange({pageNum: Math.min(currentPage.pageNum+1, pages), perPage})}
        >
          <Icon name="angle right" />
        </Menu.Item>
      )

      if (pages > 10) {
        paginate.push(
          <Menu.Item
            key={`page_pusher_right_10`}
            as='a'
            onClick={() => handlePageChange({pageNum: Math.min(currentPage.pageNum+10, pages), perPage})}
          >
            <Icon name="angle double right" />
          </Menu.Item>
        )
      }
    }
    
    if (pages <= 5 && pages > 0) {
      for (let i=0; i < pages; i++) {
        const pageNum = i+1;
        paginate.push(
          <Menu.Item
            key={`page_index_${i}`}
            as='a'
            active={currentPage.pageNum === pageNum}
            onClick={() => handlePageChange({pageNum, perPage})}
          >
            {pageNum}
          </Menu.Item>);
      }
    }

    if (pages === 0) {
      paginate.push(
        <Menu.Item
          key={`page_index_1`}
          as='a'
          active
        >
          1
        </Menu.Item>
      )
    }

    return (
      <>
        {paginate}
      </>
    )
  }

  const searchQueryFetch = _.debounce((e) => {
    if (e.length >= 3 || !e.length) {
      paginator({currentPage, perPage, query: e});
    }
  }, 300)

  const searchQuery = (e, data) => {
    setQueryValue(data.value);
    searchQueryFetch(data.value);
  }

  const paginationRender = (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={5}>
          <Menu inverted pagination>
            <Menu.Item>
              <Dropdown
                inline
                labeled
                options={[
                  {key: 0, text: 1, value: 1},
                  {key: 1, text: '10', value: 10},
                  {key: 2, text: '20', value: 20},
                  {key: 3, text: '30', value: 30},
                ]}
                value={perPage}
                onChange={(e, data) => {
                  setPerPage(data.value);
                  paginator({currentPage, perPage: data.value});
                }}
              />
              &nbsp; Kits Per Page
            </Menu.Item>
            <Menu.Item>
              <Input icon='search' value={queryValue} onChange={searchQuery} placeholder='Scan/Enter Barcode' />
            </Menu.Item>
          </Menu>
          <Menu floated='right' pagination>
            <GetPagination />
          </Menu>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );

  if (loading) {
    return <Dimmer active inverted><Loader inverted size='large' content='Loading' /></Dimmer>;
  }

  if (kitView) {
    return (<ViewKit kit={kitView} cancelView={() => setKitView(null)} />);
  }

  return (
    <>
    {loading && (<Dimmer active inverted><Loader inverted size='large' content='Loading' /></Dimmer>)}
    <Table selectable inverted striped size='small'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Barcode</Table.HeaderCell>
          <Table.HeaderCell>Kit ID</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Linked?</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Checked In?</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {kits.map(kit => (
        <Table.Row
          key={`kit_${kit.kit_id}`}
          onClick={() => setKitView(kit)}
          className={'selectable-table-row'}
        >
          <Table.Cell>{kit.barcode}</Table.Cell>
          <Table.Cell>{kit.kit_id}</Table.Cell>
          <Table.Cell textAlign='center'>
            <Icon
              name='check circle outline'
              color={kit.account === null ? 'red' : 'green'}
            />
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Icon
              name='check circle outline'
              color={!kit.dateScanned ? 'red' : 'green'}
            />
          </Table.Cell>
        </Table.Row>
      ))}
      {noneFound && (
        <Table.Row>
          <Table.Cell colSpan={5}>
            <Message fluid warning>No Kits Found.</Message>
          </Table.Cell>
        </Table.Row>
      )}
      </Table.Body>
      {paginationRender}
    </Table>
    </>
  )
}

const LabKits = props => {
  const { user, updateUser } = useContext(UserContext);
  const [kitData, setKitData] = useState({kitList: [], pages: 1});
  const [noneFound, setNoneFound] = useState(false);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const getDataForPage = (page = 1, limit = 10, query = null) => {
    setLoading(true);
    get(apiRoutes.getKits(page, limit, query), {
      'Authorization': `Bearer ${user.token}`,
    })
    .then(data => {
      if (data.success) {
        setKitData({
          kitList: data.data,
          pages: data.pages,
        });
        setInit(true);
        setLoading(false)
        if (data.data.length === 0) {
          setNoneFound(true);
        } else {
          setNoneFound(false);
        }
      } else {
        setNoneFound(true);
        setLoading(false);
      }
      updateUser({
        ...user,
        token: data.token
      })
    })
  }

  useEffect(() => {
    if (kitData.kitList.length === 0 && !init) {
      getDataForPage();
    }
  })

  return (
    <PageContainer>
      <Header>Lab - List Kits</Header>
      <KitList
        kits={kitData.kitList}
        paginator={e => getDataForPage(e.pageNum, e.perPage, e.query)}
        pages={kitData.pages}
        noneFound={noneFound}
        loading={loading}
      />
    </PageContainer>
  )
}

export default withResponsiveContainerNav(LabKits);